import React from 'react';

import ThemeProvider from 'stoomlink-commons-ui/ThemeProvider/ThemeProvider';
import Title from 'stoomlink-commons-ui/Title';
import BodyText from 'stoomlink-commons-ui/BodyText';
import Container from '../../layouts/Container';

import { ReactComponent as Mobiscore } from '../../assets/images/belgianmobilitycompany-mobiscore.svg';
import { ReactComponent as SmartDisplays } from '../../assets/images/belgianmobilitycompany-smartdisplays.svg';
import { ReactComponent as Broadcast } from '../../assets/images/belgianmobilitycompany-broadcast.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/twitter-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/images/linkedin-icon.svg';
import { ReactComponent as GithubIcon } from '../../assets/images/github-icon.svg';
// import { ReactComponent as NextmoovLogo } from '../../assets/images/nextmoov-light.svg';
// import { ReactComponent as StratecLogo } from '../../assets/images/stratec.svg';
// import { ReactComponent as ViasLogo } from '../../assets/images/vias.svg';

import { data } from '../../../content/footer';

import {
  FooterUI,
  LinkContainer,
  StyledArrow,
  Flex,
  RightColumn,
  FooterLink,
  SocialMediasContainer,
  Subfooter,
  Logo,
} from './styledComponent';


const productLogos = {
  mobiscore: <Mobiscore />,
  smartdisplays: <SmartDisplays />,
  broadcast: <Broadcast />,
};

// const cofoundersLogos = {
//   nextmoov: <NextmoovLogo />,
//   stratec: <StratecLogo />,
//   vias: <ViasLogo />,
// };

const socialMediasLogos = {
  linkedin: <LinkedinIcon />,
  twitter: <TwitterIcon />,
  github: <GithubIcon />,
};


function Footer(props) {
  const { teamLink, className } = props;
  return (
    <ThemeProvider themeStyle="dark">
      <FooterUI className={className}>
        <Container>
          <Flex>
            <div>
              <LinkContainer href="/">
                <Logo width={null} height={null} viewBox="0 0 398 31" />
              </LinkContainer>


              <div style={{ marginTop: '18px' }}>
                <FooterLink href={`mailto:${data.company.email}`}>{data.company.email}</FooterLink>
                <BodyText>
                  {`${data.company.location.address}`}
                </BodyText>
                <BodyText>{`${data.company.location.CP} ${data.company.location.city}`}</BodyText>

                <FooterLink
                  href={`tel:${data.company.phones.main}`}
                  style={{ display: 'block' }}
                >
                  <BodyText style={{ display: 'inline-block' }}>
                    Phone:
                  </BodyText>
                  {` ${data.company.phones.main}`}
                </FooterLink>

                <FooterLink
                  href={`tel:${data.company.phones.SLAHotline}`}
                >
                  <BodyText style={{ display: 'inline-block' }}>
                    Customers Hotline:
                  </BodyText>
                  {` ${data.company.phones.SLAHotline}`}
                </FooterLink>
              </div>
            </div>
            <RightColumn>
              {teamLink && (
                <LinkContainer href="/team" style={{ display: 'flex', float: 'none' }}>
                  <Title level={1}>Meet The Team</Title>
                  <StyledArrow
                    className="footer-arrow"
                    viewBox="0 0 24 24"
                  />
                </LinkContainer>
              )}
            </RightColumn>
          </Flex>
          <Subfooter>
            <SocialMediasContainer>
              {Object.keys(data.company.socialMedias).map((el, i) => {
                return (
                  <FooterLink
                    key={`${el + i}`}
                    href={data.company.socialMedias[el]}
                    target="_blank"
                  >
                    {socialMediasLogos[el]}
                  </FooterLink>
                );
              })}
            </SocialMediasContainer>
          </Subfooter>
        </Container>
      </FooterUI>
    </ThemeProvider>
  );
}

Footer.defaultProps = {
  teamLink: true,
};

export default Footer;
