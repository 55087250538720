export const data = {
  company: {
    email: 'hey@stoomlink.com',
    location: {
      address: 'Cantersteen 10/12',
      CP: '1000',
      city: 'Brussels',
    },
    phones: {
      main: '+32 78 07 90 08',
      SLAHotline: '+32 78 07 90 08',
    },
    socialMedias: {
      linkedin: 'https://www.linkedin.com/company/stoomlink/',
      twitter: 'https://twitter.com/stoomlink',
      github: 'https://github.com/nextmoov',
    },
  }
};

export default data;
